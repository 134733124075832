<template>
  <div class="modal-mask" :class="show ? 'show-modal' : 'hide-modal'">
    <div class="modal-wrapper">
      <!-- <Transition name="fade" mode="out-in"> -->
      <div class="modal-container" :class="show ? 'show' : 'hide'">
        <div class="modal-body">
          <div class="container">
            <a
              class="blog"
              href="https://medium.com/@quottieapp/"
              style="text-align: center"
              @click="hideMenu"
              >Blog</a
            >
            <a
              href="https://apps.apple.com/app/id1603440570/"
              @click="hideMenu"
              class="apple df hover_op to-hide contact"
            ></a>
          </div>
        </div>
      </div>
      <!-- </Transition> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "MobileMenu",
  props: {
    show: Boolean,
  },
  methods: {
    hideMenu() {
      this.$emit("hideMobileMenu");
    },
  },
};
</script>

<style scoped>
.apple {
  display: flex;
  padding: 12px 16px;
  border-radius: 8px;
  background-color: #000;
  width: 141px;
}
.apple::before {
  content: "";
  display: block;
  flex: none;
  width: 24px;
  height: 24px;
  margin-right: 8px;
  background: url(../../../public/assets/img/apple_icon.svg) no-repeat
    center/contain;
}
.apple::after {
  color: #fff;
  content: "App Store";
  white-space: nowrap;
  font-size: 16px;
  font-family: "Inter";
  line-height: 24px;
}
.container > a {
  text-decoration: none !important;
  color: #fff !important;
  opacity: 1;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0px;
  line-height: 24px;
  text-align: center;
  display: flex;
}
.container > a:hover {
  text-decoration: none;
  color: inherit;
}

.modal-mask {
  position: absolute;
  z-index: 9998;
  margin-top: 0px;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: none;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.contact {
  margin-top: 16px;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.modal-container {
  position: fixed;
  transition: top 100ms ease-out 0s;
  top: -73px;
  width: 100% !important;
  margin: 0px auto;
  padding: 32px 0 !important;
  background-color: #1a1a1a;
  border-radius: 2px;
}

.show {
  /* transition: top 300ms ease-in 0s; */
  top: 80px;
}
.hide {
  /* transition: top 300ms ease-in 0s; */
  top: -73px;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 0 !important;
}
.hide-modal {
  visibility: hidden;
  opacity: 0;
  transition: visibility step-end 250ms, transform ease-out 250ms,
    opacity ease-out 250ms;
  /* transition: visibility 300ms, opacity 0.8s linear; */
}
.show-modal {
  visibility: visible;
  opacity: 1;
  transition: visibility step-start 250ms, transform ease-out 250ms,
    opacity ease-out 250ms;
  /* transition: visibility 300ms, opacity 0.3s linear; */
}

.modal-default-button {
  float: right;
}
.tox-tinymce-aux {
  z-index: 99999999999 !important;
}

/*
     * The following styles are auto-applied to elements with
     * transition="modal" when their visibility is toggled
     * by Vue.js.
     *
     * You can easily play with the modal transition by editing
     * these styles.
     */

/* .fade-enter-active {
  transform: translateY(80px);
  transition: 2s;
} */
/* .fade-leave-active { */
/* transition: transform 0.2s ease; */
/* transform: translateY(100%);
  transition: all 150ms ease-in 0s; */
/* transform: translateY(-80px);
  transition: 2s;
}
.fade-enter-from {
  transform: translateY(80px);
  transition: 2s;
}
.fade-leave-to {
  transform: translateY(-80px);
  transition: 2s;

} */
/* .modal-enter-from .modal-container,
.modal-leave-to .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
} */

@media screen and (max-width: 768px) {
  .container > a {
    text-decoration: none !important;
    color: #fff !important;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;

    text-align: center;
    display: flex;
  }
  .container > a:hover {
    text-decoration: none;
    color: inherit;
  }

  .modal-mask {
    display: table;
  }
}
</style>
