<template>
  <div
    class="main-container"
    style="
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
    "
  >
    <div class="container-1">
      <div class="blog-title">
        <div class="h-title">
          <h1>Blog</h1>
        </div>
      </div>
      <div v-if="!blogs.length" style="margin-top: 5rem; margin-bottom: 5rem">
        <p>There are no blog posts yet.</p>
      </div>
      <div v-if="blogs.length" class="part-1">
        <div class="parent">
          <div
            v-for="(blog, index) in blogs"
            :key="blog.id"
            :class="'blog-post div' + (index + 1)"
          >
            <img
              :src="
                'https://firebasestorage.googleapis.com/v0/b/blogs-94dc9.appspot.com/o/PhotoGallery%2F' +
                blog.postImage +
                '?alt=media'
              "
            />
            <p
              style="
                padding-top: 32px;
                padding-bottom: 0 !important;
                opacity: 0.64;
                color: #fff;
                font-size: 12px !important;
                font-weight: 400 !important;
                font-style: normal;
                letter-spacing: 0px;
                text-align: left;
                line-height: 16px !important;
              "
            >
              {{ (new Date(blog.createdAt.seconds * 1000) + "").slice(4, 15) }}
            </p>
            <router-link :to="'/blog/' + blog.id"
              ><p
                :class="blog.title.length > 51 ? 'three-lines' : 'two-lines'"
                style="
                  padding-bottom: 0 !important;
                  margin-bottom: 0 !important;
                "
              >
                {{ blog.title }}
              </p>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import blogsRef from "../firebase";
import { getDocs } from "firebase/firestore";
import "firebase/compat/firestore";
export default {
  data() {
    return {
      blogs: [],
    };
  },
  methods: {
    async getBlogs() {
      let blogsSnapshot = await getDocs(blogsRef);
      let blogs = [];
      blogsSnapshot.forEach((blog) => {
        let blogData = blog.data();
        blogData.id = blog.id;
        if (blogData.product == "idVPN") blogs.push(blogData);
      });
      this.blogs = blogs;
    },
  },

  mounted() {
    this.getBlogs();
  },
};
</script>

<style lang="scss" scoped>
@import "../../../public/assets/css/blog.scss";
</style>
