<template>
  <main>
    <section class="terms_wrap">
      <div class="container">
        <div class="row">
          <div class="col_12">
            <h1>Terms and Conditions</h1>

            <a href="support@quottie.app" class="support_link" target="_blank"
              >support@quottie.app</a
            >

            <p>
              Please read the following terms of use carefully before using the
              Quottie application owned and operated Quottie (Serhiy Ozhibko) of
              Knyahyni Olʹhy, L’viv, L’vivs’ka oblast, 79000, Ukraine and email
              <a href="support@quottie.app" target="_blank" class="link"
                >support@quottie.app</a
              >. By downloading the and accessing the app, you agree to the
              terms of this agreement which will bind you. Your agreement to
              comply with and be bound by these terms and conditions is deemed
              to occur upon your first use of the app. If you do not agree to be
              bound by these terms and conditions, you should stop using the app
              immediately.
            </p>

            <h2>Validity</h2>

            <p>
              The Terms and Conditions set forth herein govern the use of the
              Quottie application. By using the app, the User acknowledges that
              he/she has read and understood these Terms and Conditions and
              accepts them. Deviating terms and in particular conditions of the
              user which are in conflict with the terms and conditions require
              the written consent of Quottie.
            </p>

            <h2>Scope of services</h2>

            <ol>
              <li>Quottie offers services in the form of an app</li>
              <li>
                Quottie is entitled to commission third party service providers
                and vicarious agents with the provision of parts or the entire
                range of services.
              </li>
              <li>
                Quottie may only be used for purely private, non-commercial
                purposes. Any exception must be discussed with and approved by
                us.
              </li>
              <li>
                The User has no claim to a minimum number, a minimum scope or a
                certain type, form or quality of the services. Likewise, the
                User has no claim to a certain type, form or quality of Quottie
                and/or the features.
              </li>
              <li>
                Quottie is entitled at any time to adapt, change and supplement
                Quottie and the features posted on Quottie without stating
                reasons, whereby this will be announced in the app.
              </li>
              <li>
                Quottie reserves the right to discontinue the operation of
                Quottie at any time without stating reasons. This shall also
                result in the forfeiture of any further claims of the user which
                are regulated in the Terms and Conditions.
              </li>
            </ol>

            <h2>Conclusion of contract, start of contract, prices</h2>

            <ol>
              <li>
                The contract between Quottie and the user shall be concluded
                upon free registration by the user on the app
              </li>
              <li>
                The use of the service is free of charge in the basic functions.
                The User may purchase various added values ("Premium Functions")
                for a fee. The respective prices shall be clearly and visibly
                displayed to the user in the app before the final purchase.
              </li>
              <li>
                Quottie reserves the right to change these prices at unspecified
                times.
              </li>
              <li>
                The booking and payment of Premium functions shall be made via
                the Apple App Store. The contractual partner for the conclusion
                of the Premium is Apple. The subscription can also only be
                cancelled directly via the Apple App Store, not via Quottie.
                Depending on the period selected by the User, the subscription
                shall be automatically renewed for the same period, unless the
                User cancels it within the specified period of 24 hours to the
                end of the respective period.
              </li>
              <li>
                The "in-app" purchases offered by Quottie unlock exclusive
                features in the mobile app. Payments are processed via the Apple
                App Store from which you originally installed the app. You can
                access the applicable in-app purchase rules and policies
                directly in the app stores.
              </li>
              <li>
                Use of Quottie is free of charge. However, there are certain
                added values which are reserved for paying customers.
              </li>
              <li>
                The user must be over 16 years of age and a natural person.
              </li>
              <li>
                Within the scope of booking premium functions via the Google
                Play Store or via the Apple App Store for a certain term, the
                User shall conclude a subscription which shall be automatically
                renewed depending on the period selected unless the User cancels
                it within the specified period of 48 hours to the end of the
                respective period.
              </li>
            </ol>

            <h2>Data protection</h2>

            <p>
              Quottie's privacy policy applies to all users and are part of the
              contract concluded with the user upon registration. They are
              available on the website and the app under "Privacy Policy".
            </p>

            <h2>Termination of the free membership</h2>

            <ol>
              <li>
                The user may terminate the contract with Quottie (not Apple) at
                any time without giving reasons by uninstalling the app.
              </li>
              <li>
                Quottie may terminate the contract extraordinarily at any time
                without observing a notice period if the user violates these
                terms and conditions.
              </li>
              <li>
                Quottie reserves the right to refuse the access of users without
                giving reasons.
              </li>
              <li>
                Quottie reserves the right to refuse the access of users without
                giving reasons.Quottie does not assume any liability for data
                deleted within the scope of a contract termination. There is
                also no claim to restoration. In the event of temporary
                exclusion or extraordinary termination, further claims of the
                operator of Quottie remain unaffected.
              </li>
            </ol>

            <h2>Copyright, granting of rights</h2>

            <ol>
              <li>
                Quottie and the underlying software are protected by copyright
                and may not be used or modified beyond the right of use granted
                without the prior written consent of the operator of Quottie.
              </li>
              <li>
                Likewise, the design of Quottie and, in particular, content
                posted in the app (e.g., databases, photos, images, or texts),
                are protected by copyright or are subject to other laws for the
                protection of intellectual property and, unless otherwise
                indicated, are the property of the operator of Quottie.
              </li>
              <li>As a user of the app, you agree not to:</li>

              <ul>
                <li>
                  systematically retrieve data or other content from the app to
                  create or compile, directly or indirectly, a collection,
                  compilation, database, or directory without written permission
                  from us.
                </li>
                <li>make any unauthorized use of the app.</li>
                <li>
                  circumvent, disable, or otherwise interfere with
                  security-related features of the app, including features that
                  prevent or restrict the use or copying of any Content or
                  enforce limitations on the use of the app and/or the Content
                  contained therein.
                </li>
                <li>
                  engage in unauthorized framing of or linking to the app.
                </li>
                <li>
                  trick, defraud, or mislead us and other users, especially in
                  any attempt to learn sensitive account information such as
                  user passwords;
                </li>
                <li>
                  make improper use of our support services or submit false
                  reports of abuse or misconduct.
                </li>
                <li>
                  engage in any automated use of the system, such as using
                  scripts or messages, or using any data mining, robots, or
                  similar data gathering and extraction tools.
                </li>
                <li>
                  interfere with, disrupt, or create an undue burden on the app
                  or the networks or services connected to the app.
                </li>
                <li>
                  use any information obtained from the app in order to harass,
                  abuse, or harm another person.
                </li>
                <li>
                  use the app as part of any effort to compete with us or
                  otherwise use the app and/or the Content for any
                  revenue-generating endeavour or commercial enterprise.
                </li>
                <li>
                  decipher, decompile, disassemble, or reverse engineer any of
                  the software comprising or in any way making up a part of the
                  app
                </li>
                <li>
                  attempt to bypass any measures of the app designed to prevent
                  or restrict access to the app, or any portion of the app.
                </li>
                <li>
                  delete the copyright or other proprietary rights notice from
                  any Content.
                </li>
                <li>
                  copy or adapt the app’s software, including but not limited to
                  Flash, PHP, HTML, JavaScript, or other code.
                </li>
                <li>
                  upload or transmit (or attempt to upload or to transmit)
                  viruses, Trojan horses, or other material, including excessive
                  use of capital letters and spamming (continuous posting of
                  repetitive text), that interferes with any party’s
                  uninterrupted use and enjoyment of the app or modifies,
                  impairs, disrupts, alters, or interferes with the use,
                  features, functions, operation, or maintenance of the app.
                </li>
                <li>
                  upload or transmit (or attempt to upload or to transmit) any
                  material that acts as a passive or active information
                  collection or transmission mechanism, including without
                  limitation, clear graphics interchange formats (“gifs”), 1×1
                  pixels, web bugs, cookies, or other similar devices (sometimes
                  referred to as “spyware” or “passive collection mechanisms” or
                  “pcms”).
                </li>
                <li>
                  except as may be the result of standard search engine or
                  Internet browser usage, use, launch, develop, or distribute
                  any automated system, including without limitation, any
                  spider, robot, cheat utility, scraper, or offline reader that
                  accesses the app, or using or launching any unauthorized
                  script or other software. m)disparage, tarnish, or otherwise
                  harm, in our opinion, us and/or the app.
                </li>
                <li>
                  use the app in a manner inconsistent with any applicable laws
                  or regulations.
                </li>
              </ul>
            </ol>

            <h2>Warranty, liability</h2>

            <ol>
              <li>
                Quottie does not guarantee that the user's expectations of the
                online services offered will be fulfilled.
              </li>
              <li>
                Quottie is only liable for damages of a psychological, physical
                or financial nature that occur in connection with the offered
                service, insofar as this damage is caused by a grossly negligent
                breach of duty on the part of Quottie or its legal
                representatives or vicarious agents or by intent on the part of
                Quottie or its legal representatives or vicarious agents.
              </li>
              <li>
                Quottie shall be liable for damages in connection with the
                service offered and which occur to legal assets other than life,
                limb or health, insofar as this damage is due to a grossly
                negligent breach of duty on the part of Quottie, or its legal
                representatives or vicarious agents, or to intent on the part of
                the operator, or its legal representatives or vicarious agents.
              </li>
              <li>The use of Quottie is exclusively at the user's own risk.</li>
              <li>
                Continuous, error-free operation and permanent usability are not
                guaranteed by the operator of Quottie. Temporary disruptions in
                accessibility may occur, among other things, in the course of
                maintenance work. Quottie shall not be liable for any resulting
                damage to the user, such as faulty or delayed transmission,
                incorrect, incomplete or altered content or removed data.
              </li>
              <li>
                Quottie assumes no liability for the contents. In particular,
                the operator of Quottie is not liable for damages arising from
                content created, shared or published by users.
              </li>
              <li>
                Quottie accepts no liability for the misuse of information and
                data by third parties or other users. This applies both to
                information created, shared or published by the user and to
                information and data obtained by unauthorised means, such as
                through hacker attacks.
              </li>
              <li>
                The systems of the operator of Quottie are secured against
                attacks by hackers and viruses with measures customary in
                business. However, the risk of such attacks cannot be excluded.
                Insofar as virus infestation or a hacker attack is not caused by
                the operator of Quottie due to grossly negligent or intentional
                neglect of the security settings, the operator of Quottie shall
                not assume any liability for the resulting damage.
              </li>
              <li>
                The user shall indemnify the operator of Quottie against all
                claims of third parties which they raise against the operator of
                Quottie with the assertion that contents/behaviour/actions of
                the user violate their rights and undertakes to indemnify and
                hold harmless the operator of Quottie in this respect.
              </li>
            </ol>

            <h2>Assumption of contract by third parties</h2>

            <p>
              Quottie shall be entitled to transfer its rights and obligations
              from this contractual relationship in whole or in part to a third
              party.
            </p>

            <h2>Final provisions, miscellaneous</h2>

            <ul>
              <li>
                Quottie reserves the right to amend these Terms and Conditions
                from time to time, primarily, but not limited to, in order to
                comply with legal provisions or requirements, to meet economic
                efficiency or to accommodate user interests.
              </li>
              <li>
                The user declares that he/she agrees to the application of the
                amended terms and conditions (Terms and Conditions) to contracts
                already concluded prior to the amendment if Quottie notifies the
                user that an amendment to the Terms and Conditions has taken
                place and the user does not object to the amendment within a
                period of two weeks, commencing on the day following the
                notification of the amendment.
              </li>
              <li>
                The notification of the amendment must once again contain a
                reference to the possibility and deadline for objection, as well
                as the significance or consequences of failure to object. It can
                be sent by e-mail to the e-mail address provided by the user.
              </li>
              <li>
                Should individual provisions of these Terms and Conditions be or
                become invalid, this shall not affect the remaining content of
                the Terms and Conditions. The invalid provision shall be
                replaced by a valid provision that is legally valid and comes as
                close as possible to the economic intent of the contracting
                parties.
              </li>
              <li>
                Unless otherwise stipulated in these terms and conditions,
                amendments to the contract, supplements and ancillary agreements
                must be made in writing by both parties in order to be
                effective. The written form requirement shall also apply to any
                waiver of this written form requirement.
              </li>
              <li>
                The contractual agreements of the contracting parties shall be
                governed by the law of Turkey and subject to the courts of
                Turkey to the exclusion of the UN Convention on Contracts for
                the International Sale of Goods (CISG) and the conflict of laws.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>
