<template>
  <main>
    <section class="terms_wrap">
      <div class="container">
        <div class="row">
          <div class="col_12">
            <h1>Privacy Policy</h1>

            <a href="support@quottie.app" class="support_link" target="_blank"
              >support@quottie.app</a
            >

            <p>
              Welcome to Quottie, we appreciate your interest in our app. We
              attach great importance to protecting your data and safeguarding
              your privacy. We would like to explain below which data we
              process, when and for what purpose and on what legal basis.
            </p>

            <p>
              This privacy policy contains information about our data protection
              provisions and measures as well as the rights to which you are
              entitled within the framework of the General Data Protection
              Regulation (GDPR) with regard to the manner of online collection
              and use of your information. Personal data is collected
              exclusively in accordance with those data protection laws.
            </p>

            <h2>The Controller</h2>

            <p>
              The controller within the meaning of Art. 4 No. 7 GDPR for the
              processing of personal data is Quottie (Serhiy Ozhibko) of
              Knyahyni Olʹhy, L’viv, L’vivs’ka oblast, 79000, Ukraine and email
              <a href="support@quottie.app" target="_blank" class="link"
                >support@quottie.app</a
              >. If you have any questions about the processing of your personal
              data, as well as your rights regarding data protection, please
              contact us.
            </p>

            <h2>Where this privacy policy applies</h2>

            <p>
              This privacy policy applies to the Quottie app. When the word “we”
              is used, it describes the controller (us) and includes the
              relevant service providers that help us to make the services
              available to you. When designing the app, we have made sure that
              <i>no information that directly identifies you is collected.</i>
            </p>

            <p>
              As however some countries including the EU have a broader
              definition of personal data this policy covers it. In this sense
              we would need to first of all explore the definition of personal
              data.
            </p>

            <p>
              "Personal data" means any information relating to an identified or
              identifiable natural person (hereinafter "data subject"); an
              identifiable natural person is one who can be identified, directly
              or indirectly, in particular by reference to an identifier such as
              a name, an identification number, location data, an online
              identifier (e.g., cookie) or to one or more factors specific to
              the physical, physiological, genetic, mental, economic, cultural
              or social identity of that natural person.
            </p>

            <h2>Personal data that directly identifies you</h2>

            <p>
              We do not collect personal data that directly identifies you such
              as your name or e-mail address etc. when you are using our
              services. In this sense, we will not ask you to provide any
              personal information when you are using our app.
            </p>

            <h2>Installation of our app</h2>

            <p>
              Our app can be downloaded from the Apple App Store. Downloading
              our app may require prior registration with the respective app
              store and installation of the app store software.
            </p>

            <p>
              App installation via the Apple App Store You can use the Apple
              service "App Store" a service of Apple Distribution International
              Ltd, Hollyhill Industrial Estate, Hollyhill Ln, Knocknaheeney,
              Cork, Ireland, to install our app. As far as we are aware, Apple
              collects and processes the following data:
            </p>

            <ul>
              <li>device identifiers,</li>
              <li>IP addresses,</li>
              <li>location information,</li>
            </ul>
            <br /><br />

            <p>
              It cannot be excluded that Apple also transmits the information to
              a server in a third country. This could in particular be Apple
              Inc. One Apple Park Way, Cupertino, California, USA, 95014. We
              cannot influence which personal data Apple processes with your
              registration and the provision of downloads in the respective app
              store and app store software. The responsible party in this
              respect is solely Apple as the operator of the Apple App Store.
              You can find more detailed information in Apple's privacy policy,
              which you can access here:
              <a href="https://www.apple.com/legal/privacy/" class="link"
                >https://www.apple.com/legal/privacy/</a
              >.
            </p>

            <h2>Log files</h2>

            <p>
              When you call up our app, we automatically collect data and
              information from your device's system and store some of this in
              so-called server log files. The data is automatically transmitted
              by your device when you call up our app. This includes the
              following data:
            </p>

            <ul>
              <li>IP address</li>
              <li>Date and time of your request</li>
              <li>Time zone difference and Greenwich Mean Time (GMT)</li>
              <li>Content of the request (specific page)</li>
              <li>Access status / http status code</li>
              <li>Amount of data transferred in each case</li>
              <li>Website from which the request came</li>
              <li>Browser</li>
              <li>Operating system and its interface</li>
              <li>Language and version of the browser software</li>
            </ul>
            <br /><br />

            <p>
              The purpose of this processing is the retrievability of our app
              from your device and to enable a correct display of our app on
              your device or in your browser. Furthermore, we use the data to
              optimise our app and to ensure the security of our systems. The
              processing of your IP address by our system is also necessary to
              enable the delivery of our app to your device. An evaluation of
              this data for marketing purposes does not take place.
            </p>

            <p>
              The legal basis for the processing is Art. 6 para. 1 lit. f) GDPR.
              We have a legitimate interest in presenting you with an app
              optimised for your browser and in enabling communication between
              our server and your terminal device. For the latter, the
              processing of your IP address in particular is necessary. We also
              collect the data to ensure stability and security for you.
            </p>

            <p>
              The data is stored by us for 30 days. The recipient of the data is
              our server host, who works for us within the framework of an order
              data agreement.
            </p>

            <h2>What data is processed when using our app?</h2>

            <p>
              When you install our app, the data required for the start-up is
              collected and processed. This is the data that you agreed to the
              collection of, insofar as you have granted the corresponding
              authorisations to Apple. The following data is processed in the
              course of using our app:
            </p>

            <ul>
              <li>General app accesses</li>
              <li>User Generated Content</li>
              <li>Push messages ("Message Usage")</li>
              <li>
                Network access (full network access, obtain internet data,
                retrieve network connections, retrieve WLAN connections)
              </li>
            </ul>

            <h2>General app accesses</h2>

            <p>
              As with every server request, information such as IP address, user
              agent etc. is transmitted and stored anonymously in the server log
              for 30 days. The provision of personal data is neither legally nor
              contractually required, nor is it necessary for the conclusion of
              a contract. You are also not obliged to provide the personal data.
              However, failure to provide the data could result in you not being
              able to use our app or not being able to use it to its full
              extent. The legal basis for this data processing is Art. 6 para. 1
              lit. a) GDPR.
            </p>

            <h2>Push messages</h2>

            <p>
              The legal basis for Push messages is Art. 6 para. 1 lit. f) GDPR.
              A push service is used to provide you with useful tips and
              information directly on your mobile device or similar devices.
              When we send a push message, we send the message with the
              corresponding IDs or tokens to the Push Notification Service. This
              then ensures that the push message is sent to the devices that
              wish to receive such a notification. Our legitimate interest is to
              be able to present current information to you directly. The
              personal data will only be processed as long as this is necessary
              for the provision of the function. You have the right to object.
              You can prevent your data from being processed further by
              deactivating the push service in the respective system settings of
              the operating system of your device.
            </p>

            <h2>Network access</h2>

            <p>
              The legal basis for this data processing is Art. 6 para. 1 lit. b)
              GDPR. Your data will be treated confidentially by us and deleted
              if you revoke the rights to use it or it is no longer required to
              provide the services and there is no legal obligation to retain
              it. The provision of personal data is necessary if you wish to
              make full use of our app. However, failure to provide this data
              could result in you not being able to use our app or not being
              able to use it to its full extent.
            </p>

            <h2>Image Gallery access</h2>

            <p>
              In order to change the background of quotes we require to access
              your image library, images, photos and other data from your camera
              and gallery. The legal basis for this data processing is Art. 6
              para. 1 lit. a) GDPR. Your image library, images, photos, and the
              other data accessed will be treated confidentially by us and
              deleted if you revoke your consent. The access to image library,
              images, photos is necessary if you wish to change the background
              images of your quotes.
            </p>

            <h2>Use of Google SDK / Google Firebase / Google Analytics</h2>

            <p>
              We use the developer platform Google Firebase and the associated
              functions and services of Google LLC ("Google"), 1600 Amphitheatre
              Parkway, Mountain View, CA 94043, USA and Google Ireland Limited
              ("Google"), Gordon House, Barrow Street, Dublin 4, Ireland. Google
              Firebase is a platform for developers of apps for mobile devices
              and websites.
            </p>

            <p>
              The Google Firebase developer platform offers a variety of
              features. You can find a list of these at:
              <a
                href="https://firebase.google.com/terms/"
                class="link"
                target="_blank"
                >https://firebase.google.com/terms/</a
              >.
            </p>

            <p>
              By integrating Google services, Google may collect and process
              information (including personal data). It cannot be ruled out that
              Google also transmits the information to a server in a third
              country. We cannot influence what data Google actually collects
              and processes. However, Google states that, in principle, the
              following information (including personal data) may be processed,
              among others:
            </p>

            <ul>
              <li>Log data (in particular the IP address)</li>
              <li>Unique application numbers</li>
              <li>Cookies and similar technologies</li>
            </ul>
            <br /><br />

            <p>
              Information on the types of cookies used by Google can be found at
              <a
                href="https://policies.google.com/technologies/types"
                class="link"
                target="_blank"
                >https://policies.google.com/technologies/types</a
              >.
            </p>

            <p>
              If you are logged into your Google account, Google may add the
              processed information to your account and treat it as personal
              data, depending on your account settings. You can prevent this
              data from being added directly by logging out of your Google
              account or also by making the appropriate account settings in your
              Google account. Furthermore, you can prevent the installation of
              cookies - insofar as Google sets them - by making the appropriate
              settings in your terminal device; however, we would like to point
              out that in this case you may not be able to use all the functions
              of our app to their full extent.
            </p>

            <h2>Firebase Firestore</h2>

            <p>
              We use the Firebase Firestore service in our app, a service
              provided by Google LLC ("Google"), 1600 Amphitheatre Parkway,
              Mountain View, CA 94043, USA and Google Ireland Limited
              ("Google"), Gordon House, Barrow Street, Dublin 4, Ireland.
            </p>

            <p>
              The Firebase Firestore is a web hosting and backend service
              provided by Google LLC or by Google Ireland Limited, depending on
              the location from which this application is accessed. Cloud
              Firestore is a fast, fully managed, serverless, cloudnative NoSQL
              database for documents that simplifies the storage,
              synchronisation and retrieval of data for mobile, web and IoT
              applications worldwide.
            </p>

            <p>
              The Firebase Firestore is used by us to store information such as
              bookmarks in the app internal browser. We have concluded a
              contract for commissioned processing with Google for the use of
              Google Firebase Firestore (Art. 28 GDPR). Google processes the
              data on our behalf in order to transmit data stored in your app
              internal browser to your terminal device. Google may transfer this
              information to third parties where required to do so by law, or
              where such third parties process the information on Google's
              behalf.
            </p>

            <p>
              By integrating Firebase Firestore, we pursue the purpose of being
              able to offer you a better service within the scope of our app.
            </p>

            <p>
              The legal basis for the processing of personal data described here
              is Art. 6 (1) f GDPR. Our necessary legitimate interest lies in
              the great benefit that the functions described above have for our
              offer. The use of Google Firebase Firestore gives us the
              opportunity to provide you with better use and information through
              our app.
            </p>

            <p>
              As part of the order processing, Google is entitled to use
              subcontractors. A list of these subcontractors can be found at
              <a
                href="https://privacy.google.com/businesses/subprocessors/"
                class="link"
                target="_blank"
                >https://privacy.google.com/businesses/subprocessors/</a
              >.
            </p>

            <p>
              You have a right to object. You can prevent your data from being
              processed further by deactivating the Firebase Firestore service
              in the app.
            </p>

            <p>
              The processed information will be stored for 6 months and
              automatically deleted after this retention period.
            </p>

            <p>
              For further information on data handling in connection with Google
              Analytics, please refer to Google's privacy policy: <br />
              <a
                href="https://support.google.com/analytics/answer/6004245?hl=en"
                class="link"
                target="_blank"
                >https://support.google.com/analytics/answer/6004245?hl=en
              </a>
              <br />
              <a
                href="https://firebase.google.com/support/privacy"
                class="link"
                target="_blank"
                >https://firebase.google.com/support/privacy
              </a>
            </p>

            <p>
              Information on Google's privacy settings can be found at
              <a
                href="https://privacy.google.com/take-control.html?categories_activeEl=sign-in"
                class="link"
                target="_blank"
                >https://privacy.google.com/take-control.html?categories_activeEl=sign-in</a
              >.
            </p>

            <h2>Google Analytics</h2>

            <p>
              The Quottie app uses Google Analytics, a web analytics service
              provided by Google Inc. ("Google"). Google Analytics uses
              "cookies" and session files that are stored on your smartphone and
              that enable an analysis of your use of the app. The information
              generated by the cookie or session files about your use of the app
              (including your IP address in anonymised form) will be transmitted
              to and stored by Google on servers in the United States. Google
              will use this information for the purpose of evaluating your use
              of the app, compiling reports on activity for us and providing
              other services relating to app activity and internet usage. Google
              will not associate your IP address with any other data held by
              Google. You may refuse the use of cookies by selecting the
              appropriate settings on your device, however, please note that if
              you do this you may not be able to use the full functionality of
              the app. By using the app, you consent to the processing of data
              about you by Google in the manner and for the purposes set out
              above.
            </p>

            <p>
              The legal basis for the processing of personal data described here
              is Art. 6 (1) f GDPR. Our necessary legitimate interest lies in
              the great benefit that the functions described above have for our
              offer. With the service, we open up the possibility for you to
              experience the same personalised experience on all devices and
              thus enable you to better use and inform yourself through our app.
            </p>

            <p>
              As part of the order processing, Google is entitled to use
              subcontractors. A list of these subcontractors can be found at
              <a
                href="https://privacy.google.com/businesses/subprocessors/"
                class="link"
                target="_blank"
                >https://privacy.google.com/businesses/subprocessors/</a
              >.
            </p>

            <p>
              You have a right to object. You can prevent your data from being
              processed further by deactivating the cookies in your device.
            </p>

            <p>
              The processed information will be stored for 6 months and
              automatically deleted after this retention period.
            </p>

            <p>
              For further information on data handling in connection with Google
              Analytics, please refer to Google's privacy policy: <br />
              <a
                href="https://support.google.com/analytics/answer/6004245?hl=en"
                class="link"
                target="_blank"
                >https://support.google.com/analytics/answer/6004245?hl=en</a
              >
              <br />
              <a
                href="https://firebase.google.com/support/privacy"
                class="link"
                target="_blank"
                >https://firebase.google.com/support/privacy
              </a>
            </p>

            <p>
              Information on Google's privacy settings can be found at
              <a
                href="https://privacy.google.com/take-control.html?categories_activeEl=sign-in"
                class="link"
                target="_blank"
                >https://privacy.google.com/take-control.html?categories_activeEl=sign-in</a
              >.
            </p>

            <h2>Contacting us</h2>

            <p>
              You have the option of contacting us. If you contact us by e-mail,
              we will process in particular your e-mail address, the time of the
              e-mail and the data resulting from the message text (including
              attachments, if applicable). The purpose of processing the
              above-mentioned data is to process the contact enquiry and to be
              able to contact the enquirer in order to respond to the request.
              The legal basis for the processing of personal data described here
              is Art. 6 (1) f GDPR. Our legitimate interest is to offer you the
              possibility to contact us at any time and to be able to answer
              your enquiries. The personal data will be deleted as soon as they
              are no longer required to achieve the purpose for which they were
              collected.
            </p>

            <p>
              You have a right of objection. You can send or inform us of your
              objection at any time. Please use the address of the person
              responsible for the app on the subject of data protection.
            </p>

            <p>
              The provision of personal data is neither legally nor
              contractually required and is also not necessary for the
              conclusion of a contract. You are also not obliged to provide the
              personal data. However, failure to provide it would, under certain
              circumstances, mean that you would not be able to use the option
              to contact us or that we would not be able to contact you.
            </p>

            <h2>How long do we store your data?</h2>

            <p>
              We only store your data for as long as it is required to fulfil
              the purpose for which it was collected (e.g., within the scope of
              a contractual relationship) or if this is provided for by law. For
              example, in the context of a contractual relationship, we store
              your data at least until the complete termination of the contract.
              Afterwards, your data will be stored for the duration of the legal
              retention periods.
            </p>

            <h2>Passing on personal data</h2>

            <p>
              Your data will only be passed on if we have informed you of this
              in the above information. Otherwise, personal data will not be
              disclosed without your express consent, unless we are legally
              obliged to disclose data (information to law enforcement agencies
              and courts; information to public bodies that receive data on the
              basis of statutory provisions, e.g., social insurance
              institutions, tax authorities, etc.) or we involve third parties
              bound to professional secrecy to enforce our claims.
            </p>

            <h2>Are you obliged to provide personal data?</h2>

            <p>
              If you use the services offered by our app and wish to use the
              full range of functions of the app, you must provide the personal
              data that is required for the respective service. Failure to
              provide this data would result in you not being able to use our
              app or not being able to use all of its functions.
            </p>

            <h2>
              Non-existence of automated decision-making including profiling.
            </h2>

            <p>
              Automated decision making and / or profiling does not take place
              when using our app.
            </p>

            <h2>Data security</h2>

            <p>
              We secure our app and other systems through technical and
              organisational measures against loss, destruction, access,
              modification or distribution of your data by unauthorised persons.
              However, despite regular checks, complete protection against all
              dangers is not possible.
            </p>

            <h2>Data subject rights</h2>

            <p>
              In principle, you have the following rights: <br />
              Right to information (Art. 15 GDPR).<br />
              Right to rectification (Art. 16 GDPR)<br />
              Right to object (Art. 21 GDPR)<br />
              Right to erasure (Art. 17 GDPR)<br />
              Right to restriction of processing (Art. 18f. GDPR)<br />
              Right to data portability (Art. 20 GDPR)<br />
            </p>

            <p>
              For requests of this nature, please contact us. Please note that
              when making such requests, we must ensure that it is actually the
              data subject.
            </p>

            <p>
              Without prejudice to any other administrative or judicial remedy,
              you have the right to lodge a complaint with a data protection
              supervisory authority.
            </p>

            <h2>Changes to the privacy policy</h2>

            <p>
              Changes in the law or changes in our internal processes may make
              it necessary to amend this privacy policy. In the event of such a
              change, we will inform you of this in our privacy policy.
            </p>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>
