<template>
  <div id="app">
    <the-header></the-header>
    <router-view></router-view>
    <the-footer></the-footer>
  </div>
</template>

<script>
import TheFooter from "./components/UI/TheFooter.vue";
import TheHeader from "./components/UI/TheHeader.vue";

export default {
  components: { TheHeader, TheFooter },
  name: "App",
};
</script>

<style>
* {
  font-family: "Inter";
}
</style>
