import { initializeApp } from "firebase/app";
import { getFirestore, collection } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyCRNMCKlozWuh9TznZBvS3IqTwP7Cq_8gw",
  authDomain: "blogs-94dc9.firebaseapp.com",
  projectId: "blogs-94dc9",
  storageBucket: "blogs-94dc9.appspot.com",
  messagingSenderId: "746901768975",
  appId: "1:746901768975:web:4d6a369ca0d433c83ccc7a",
  measurementId: "G-B3J5FPMXSG",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const blogsRef = collection(db, "blogs");
export default blogsRef;
