<template>
  <header class="df s_betwen">
    <a href="/" class="" style="height: 48px; width: 140px">
      <img
        src="../../../public/assets/img/logo.svg"
        class="img-logo"
        style="height: 48px; width: 140px"
        alt=""
      />
    </a>
    <div class="btn">
      <a
        class="to-hide blogmenu"
        href="https://medium.com/@quottieapp/"
        style="margin-right: 32px"
        >Blog</a
      >
      <a
        href="https://apps.apple.com/app/id1603440570/"
        class="apple df hover_op to-hide"
      ></a>
      <img
        v-show="!showMenuModal"
        @click="hideShow"
        class="burger-menu"
        src="../../../public/assets/img/list.svg"
      />
      <img
        v-show="showMenuModal"
        @click="hideShow"
        class="burger-menu"
        src="../../../public/assets/img/x.svg"
      />
    </div>
    <Teleport to="body">
      <MobileMenu :show="showMenuModal" @hideMobileMenu="hideMobileMenu">
      </MobileMenu>
    </Teleport>
  </header>
</template>

<style scoped>
/* @import "../../assets/css/styles.css"; */
</style>

<script>
import MobileMenu from "./MobileMenu.vue";

export default {
  data() {
    return {
      showMenuModal: false,
      isCustomizePage: false,
    };
  },
  props: ["testing"],
  components: {
    MobileMenu,
  },
  methods: {
    hideMobileMenu() {
      this.showMenuModal = false;
    },
    hideShow() {
      this.showMenuModal = !this.showMenuModal;
    },
  },
  mounted() {
    if (this.$route.path?.includes("blog")) {
      this.isCustomizePage = true;
    } else {
      this.isCustomizePage = false;
    }
  },
  watch: {
    $route() {
      if (this.$route.path?.includes("blog")) {
        this.isCustomizePage = true;
      } else {
        this.isCustomizePage = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
* {
  font-family: "Inter";
}
.s_betwen {
  z-index: 9999;
  .btn {
    display: flex;
    .blogmenu {
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;

      font-size: 16px;
      font-weight: 400;
      font-style: normal;
      letter-spacing: 0px;
      line-height: 24px;
      text-align: center;
    }
    .burger-menu {
      display: none;
    }
  }
}

@media screen and (max-width: 768px) {
  .s_betwen {
    .btn {
      padding: 0;
      .to-hide {
        display: none;
      }
      .burger-menu {
        display: inline-block;
        background-color: black;
        padding: 12px;
        border-radius: 8px;
      }
    }
  }
}
</style>
