<template>
  <main>
    <section class="main_top">
      <div class="container">
        <div class="row">
          <div class="col_l_6 col_xl_6 col_m_12 col_s_12">
            <div class="w522 left">
              <h1>Take motivation from others</h1>

              <p class="main_slogan op_64">
                A daily dose of motivation to achieve long-delayed but essential
                goals. Worth a try, and most importantly, it's free.
              </p>

              <a
                href="https://apps.apple.com/app/id1603440570/"
                target="_blank"
                class="dwnld hover_op"
              ></a>
            </div>
          </div>

          <div class="col_l_6 col_xl_6 col_m_12 col_s_12">
            <img
              srcset="
                ../../../public/assets/images/hero-illustration/hero-illustration.png,
                ../../../public/assets/images/hero-illustration/hero-illustration-2x.png 2x,
                ../../../public/assets/images/hero-illustration/hero-illustration-3x.png 3x
              "
              alt=""
              class="phone right"
            />
          </div>
        </div>
      </div>
    </section>

    <div class="marquee">
      <p>
        <span>“Words, once they are printed, have a life of their own.”</span>
        <span>“Words, once they are printed, have a life of their own.”</span>
        <span>“Words, once they are printed, have a life of their own.”</span>
        <span>“Words, once they are printed, have a life of their own.”</span>
        <span>“Words, once they are printed, have a life of their own.”</span>
        <span>“Words, once they are printed, have a life of their own.”</span>
      </p>
    </div>

    <section class="customize">
      <div class="container">
        <div class="row">
          <div class="col_l_6 col_xl_6 col_m_12 col_s_12">
            <div class="w522 left">
              <h2>Customize to your own needs</h2>
            </div>
          </div>

          <div class="col_l_6 col_xl_6 col_m_12 col_s_12">
            <p class="text op_64 w522 right">
              Have you ever experienced this feeling when everything becomes a
              routine? Everyday affairs, which once seemed quite fun, became
              quite dull. How to restore that sense of satisfaction? Everything
              is straightforward. We have prepared an incredibly massive library
              of topics for all occasions. You can always choose something that
              directly reflects your mood. <br /><br />

              But most importantly, you can be creative and create power themes
              just in case. Our editor allows you to create everything your
              heart desires.
            </p>
          </div>

          <div class="col_12">
            <img
              srcset="
                ../../../public/assets/img/rounds.png,
                ../../../public/assets/img/rounds@2x.png 2x,
                ../../../public/assets/img/rounds@3x.png 3x
              "
              alt=""
              class="rounds"
            />
          </div>
        </div>
      </div>
    </section>

    <section class="motivation">
      <div class="container">
        <div class="row">
          <div class="col_12">
            <h2>
              Motivation is a powerful but cunning beast. Here are some examples
              of quotes that you can get inspired by every day
            </h2>
          </div>
        </div>

        <div class="row">
          <div class="col_l_12 col_xl_12 col_m_12 show-desktop">
            <div class="df feedback_desctop">
              <div class="df w358">
                <p class="text">
                  “All the conservation efforts in the world won't be enough to
                  make a dent in the oncoming sustainability crisis our planet
                  faces.”
                </p>
                <p class="name">by Naveen Jain</p>
              </div>

              <div class="df w358">
                <p class="text">
                  “I was just, like, all I want to do is be really good at
                  something. Really, really good at something, so people are
                  vaguely impressed by me.”
                </p>
                <p class="name">by Victoria Pendleton</p>
              </div>

              <div class="df w358">
                <p class="text">
                  “Tennis is so competitive. I guess that's the way it has to
                  be.”
                </p>
                <p class="name">by Monica Seles</p>
              </div>

              <!-- <div class="dots show-dots">
                <div
                  class="dot"
                  @click="selected = 1"
                  :style="selected == 1 ? 'opacity: 0.16' : 'opacity: 1'"
                ></div>
                <div
                  class="dot"
                  @click="selected = 2"
                  :style="selected == 2 ? 'opacity: 0.16' : 'opacity: 1'"
                ></div>
                <div
                  class="dot"
                  @click="selected = 3"
                  :style="selected == 3 ? 'opacity: 0.16' : 'opacity: 1'"
                ></div>
              </div> -->
            </div>
          </div>

          <!-- <div class="col_s_12 show-mobile">
            <div class="slider" @wheel="getPosition">
              <div class="slides">
                <div id="slide-1" ref="slide1">
                  <p class="text">
                    “All the conservation efforts in the world won't be enough
                    to make a dent in the oncoming sustainability crisis our
                    planet faces.”
                  </p>
                  <p class="name">by Naveen Jain</p>
                </div>
                <div id="slide-2" ref="slide2">
                  <p class="text">
                    “I was just, like, all I want to do is be really good at
                    something. Really, really good at something, so people are
                    vaguely impressed by me.”
                  </p>
                  <p class="name">by Victoria Pendleton</p>
                </div>
                <div id="slide-3" ref="slide3">
                  <p class="text">
                    “Tennis is so competitive. I guess that's the way it has to
                    be.”
                  </p>
                  <p class="name">by Monica Seles</p>
                </div>
              </div>

              <div class="dots show-dots" style="margin-top: 32px">
                <div
                  class="dot"
                  @click="selected = 1"
                  :style="selected == 1 ? 'opacity: 0.16' : 'opacity: 1'"
                ></div>
                <div
                  class="dot"
                  @click="selected = 2"
                  :style="selected == 2 ? 'opacity: 0.16' : 'opacity: 1'"
                ></div>
                <div
                  class="dot"
                  @click="selected = 3"
                  :style="selected == 3 ? 'opacity: 0.16' : 'opacity: 1'"
                ></div>
              </div>
            </div>
          </div> -->
        </div>
      </div>
    </section>

    <section class="interesed freedom">
      <div class="container">
        <div class="row align_center">
          <div class="col_l_6 col_xl_6 col_m_12 col_s_12">
            <img
              srcset="
                ../../../public/assets/images/notifications-screen/notifications-screen.png,
                ../../../public/assets/images/notifications-screen/notifications-screen-2x.png 2x,
                ../../../public/assets/images/notifications-screen/notifications-screen-3x.png 3x
              "
              alt=""
              class="phone left"
            />
          </div>

          <div class="col_l_6 col_xl_6 col_m_12 col_s_12">
            <div class="w522 right">
              <h2>Freedom to modify your notifications</h2>

              <p class="text op_64">
                You can decide what notifications to receive, where to receive
                them, and how often to get them.
              </p>

              <div class="benefits">
                <div>
                  <img
                    src="../../../public/assets/icons/notification-icon.svg"
                    alt=""
                  />

                  <p>
                    Personalize your inspiration with adjustable notifications
                  </p>
                </div>

                <div>
                  <img
                    src="../../../public/assets/icons/adjust-icon.svg"
                    alt=""
                  />

                  <p>The ability to alter your notifications is yours</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="interesed inspiration">
      <div class="container">
        <div class="row align_center">
          <div class="col_l_6 col_xl_6 col_m_12 col_s_12 mob_order_2">
            <div class="w522 left">
              <h2>Inspiration at your fingertips</h2>

              <p class="text op_64">
                Whether you're looking for a quick pick-me-up or seeking
                long-term inspiration, our app has something for everyone.
              </p>

              <div class="benefits">
                <div>
                  <img
                    src="../../../public/assets/icons/beach-icon.svg"
                    alt=""
                  />

                  <p>Find the perfect words to push you towards your goals</p>
                </div>

                <div>
                  <img
                    src="../../../public/assets/icons/flame-icon.svg"
                    alt=""
                  />

                  <p>The final and highest source of inspiration and drive</p>
                </div>
              </div>
            </div>
          </div>

          <div class="col_l_6 col_xl_6 col_m_12 col_s_12 mob_order_1">
            <img
              srcset="
                ../../../public/assets/images/inspiration-screen/inspiration-screen.png,
                ../../../public/assets/images/inspiration-screen/inspiration-screen-2x.png 2x,
                ../../../public/assets/images/inspiration-screen/inspiration-screen-3x.png 3x
              "
              alt=""
              class="phone right"
            />
          </div>
        </div>
      </div>
    </section>

    <section class="interesed no-p">
      <div class="container">
        <div class="row align_center">
          <div class="col_l_6 col_xl_6 col_m_12 col_s_12">
            <img
              srcset="
                ../../../public/assets/img/Ill03.png,
                ../../../public/assets/img/Ill03@2x.png 2x,
                ../../../public/assets/img/Ill03@3x.png 3x
              "
              alt=""
              class="phone left"
            />
          </div>

          <div class="col_l_6 col_xl_6 col_m_12 col_s_12">
            <div class="w522 right">
              <h2>Interested in improving certain areas of life?</h2>

              <p class="text op_64">
                Hard to find something suitable? We offer a large list of
                categories for any occasion.
              </p>

              <div class="benefits">
                <div>
                  <img src="../../../public/assets/img/bell_icon.svg" alt="" />

                  <p>Make your goal part of your routine by using reminders</p>
                </div>

                <div>
                  <img src="../../../public/assets/img/heart_icon.svg" alt="" />

                  <p>Ability to save and share your favorite quotes</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="interesed">
      <div class="container">
        <div class="row align_center">
          <div class="col_l_6 col_xl_6 col_m_12 col_s_12 mob_order_2">
            <div class="w522 left">
              <h2>Choose from a large number of themes</h2>

              <p class="text op_64">
                We have prepared an infinite number of topics that will help you
                express your individuality and personality.
              </p>

              <div class="benefits">
                <div>
                  <img
                    src="../../../public/assets/img/target_icon.svg"
                    alt=""
                  />

                  <p>Mindfulness helps keep you relaxed and focused</p>
                </div>

                <div>
                  <img src="../../../public/assets/img/smile_icon.svg" alt="" />

                  <p>Sometimes you just need to take a break</p>
                </div>
              </div>
            </div>
          </div>

          <div class="col_l_6 col_xl_6 col_m_12 col_s_12 mob_order_1">
            <img
              srcset="
                ../../../public/assets/img/Ill04.png,
                ../../../public/assets/img/Ill04@2x.png 2x,
                ../../../public/assets/img/Ill04@3x.png 3x
              "
              alt=""
              class="phone right"
            />
          </div>
        </div>
      </div>
    </section>

    <section class="interesed">
      <div class="container">
        <div class="row align_center">
          <div class="col_l_6 col_xl_6 col_m_12 col_s_12">
            <img
              srcset="
                ../../../public/assets/img/Ill05.png,
                ../../../public/assets/img/Ill05@2x.png 2x,
                ../../../public/assets/img/Ill05@3x.png 3x
              "
              alt=""
              class="phone left"
            />
          </div>

          <div class="col_l_6 col_xl_6 col_m_12 col_s_12">
            <div class="w522 right">
              <h2>Show your imagination and creativity</h2>

              <p class="text op_64">
                With us you can customize your themes easily and quickly. Choose
                wallpaper and edit the text as you want.
              </p>

              <div class="benefits">
                <div>
                  <img src="../../../public/assets/img/like_iocn.svg" alt="" />

                  <p>Reward yourself when you have completed a step or goal</p>
                </div>

                <div>
                  <img src="../../../public/assets/img/stats_iocn.svg" alt="" />

                  <p>Regularly review your goals and progress</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="interesed accessible">
      <div class="container">
        <div class="row align_center">
          <div class="col_l_6 col_xl_6 col_m_12 col_s_12 mob_order_2">
            <div class="w522 left">
              <h2>Easily accessible with Siri Shortcuts</h2>

              <p class="text op_64">
                We offer a variety of inspiring quotes that can be easily
                accessed through Siri Shortcuts.
              </p>

              <div class="benefits">
                <div>
                  <img
                    src="../../../public/assets/icons/candy-icon.svg"
                    alt=""
                  />

                  <p>Motivational quotes to boost your day</p>
                </div>

                <div>
                  <img
                    src="../../../public/assets/icons/face-id-icon.svg"
                    alt=""
                  />

                  <p>Effortlessly retrievable with Siri Shortcuts</p>
                </div>
              </div>
            </div>
          </div>

          <div class="col_l_6 col_xl_6 col_m_12 col_s_12 mob_order_1">
            <img
              srcset="
                ../../../public/assets/images/siri-shortcuts-screen/siri-shortcuts-screen.png,
                ../../../public/assets/images/siri-shortcuts-screen/siri-shortcuts-screen-2x.png 2x,
                ../../../public/assets/images/siri-shortcuts-screen/siri-shortcuts-screen-3x.png 3x
              "
              alt=""
              class="phone right"
            />
          </div>
        </div>
      </div>
    </section>

    <section class="interesed">
      <div class="container">
        <div class="row align_center">
          <div class="col_l_6 col_xl_6 col_m_12 col_s_12">
            <img
              srcset="
                ../../../public/assets/images/reminders-screen/reminders-screen.png,
                ../../../public/assets/images/reminders-screen/reminders-screen-2x.png 2x,
                ../../../public/assets/images/reminders-screen/reminders-screen-3x.png 3x
              "
              alt=""
              class="phone left"
            />
          </div>

          <div class="col_l_6 col_xl_6 col_m_12 col_s_12">
            <div class="w522 right">
              <h2>Scheduled reminders that suit you</h2>

              <p class="text op_64">
                With the ability to set reminders, you can choose how many
                quotes you want to receive per day and at what time.
              </p>

              <div class="benefits">
                <div>
                  <img
                    src="../../../public/assets/icons/focus-icon.svg"
                    alt=""
                  />

                  <p>Get a boost of inspiration with reminders</p>
                </div>

                <div>
                  <img
                    src="../../../public/assets/icons/calendar-time-icon.svg"
                    alt=""
                  />

                  <p>Stay motivated with reminders tailored to your needs</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="bottom">
      <div class="container">
        <div class="row">
          <div class="col_12">
            <h3>Change your life with Quottie</h3>

            <a
              href="https://apps.apple.com/app/id1603440570/"
              target="_blank"
              class="dwnld hover_op"
            ></a>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
export default {
  data() {
    return {
      selected: 1,
      position: 0,
    };
  },
  watch: {
    position(value) {
      if (value <= 16 && value > -90) {
        this.selected = 1;
      } else if (value < -160 && value > -460) {
        this.selected = 2;
      } else {
        this.selected = 3;
      }
    },
  },
  mounted() {
    this.position = this.$refs["slide1"].getBoundingClientRect().x;
  },
  methods: {
    getPosition() {
      this.position = this.$refs["slide1"].getBoundingClientRect().x;
    },
  },
};
</script>
