<template>
  <div
    style="
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
    "
  >
    <div
      class="main-container"
      style="
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
      "
    >
      <div class="container-1">
        <div class="header-image">
          <img
            :src="
              'https://firebasestorage.googleapis.com/v0/b/blogs-94dc9.appspot.com/o/PhotoGallery%2F' +
              getBlog?.headerImage +
              '?alt=media'
            "
          />
        </div>
        <div class="title">
          <div class="h-title">
            <h1>{{ getBlog?.title }}</h1>
          </div>
        </div>

        <div class="datepart">
          <p>
            {{
              (new Date(getBlog?.createdAt.seconds * 1000) + "").slice(4, 15)
            }}
          </p>
        </div>
        <div class="part-2">
          <div class="content" v-html="getBlog?.description"></div>
        </div>
      </div>
    </div>
    <div
      class="second-main-container"
      style="
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
      "
    >
      <div class="container-1">
        <div class="blog-title">
          <div class="h-title" style="margin-bottom: 0 !important">
            <h1>Recent posts</h1>
          </div>
        </div>
        <div class="part-3">
          <div class="recent-blogs">
            <div
              v-for="(blog, index) in blogs.slice(-3)"
              :key="blog?.id"
              :class="'postImage div' + (index + 1)"
            >
              <img
                :src="
                  'https://firebasestorage.googleapis.com/v0/b/blogs-94dc9.appspot.com/o/PhotoGallery%2F' +
                  blog?.postImage +
                  '?alt=media'
                "
              />
              <p>
                {{
                  (new Date(blog.createdAt.seconds * 1000) + "").slice(4, 15)
                }}
              </p>
              <router-link class="blog-link" :to="'/blog/' + blog?.id"
                ><p>
                  {{ blog?.title }}
                </p>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <section class="bottom">
      <div class="container">
        <div class="row">
          <div class="col_12">
            <h3>Change your life with Quottie</h3>

            <a href="" target="_blank" class="dwnld hover_op"></a>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import blogsRef from "../firebase";
import { getDocs } from "firebase/firestore";
import "firebase/compat/firestore";
export default {
  data() {
    return {
      blogs: [],
      blog: {},
    };
  },
  methods: {
    async getBlogs() {
      let blogsSnapshot = await getDocs(blogsRef);
      let blogs = [];
      blogsSnapshot.forEach((blog) => {
        let blogData = blog.data();
        blogData.id = blog.id;
        if (blogData.product == "idVPN") blogs.push(blogData);
      });
      this.blogs = blogs;
    },
  },

  mounted() {
    this.getBlogs();
  },
  computed: {
    getBlog() {
      return this.blogs.find((x) => x.id == this.$route.params.id);
    },
  },
};
</script>

<style lang="scss" scoped>
.main-container {
  .container-1 {
    .header-image {
      img {
        max-height: 358px;
        height: auto;
      }
    }
    .part-2 {
      width: calc(100% - 64px) !important;
      .content {
        :deep(p) {
          font-size: 24px;
          line-height: 32px;
          padding-bottom: 32px;
          font-weight: 400;
          margin-bottom: 0;
        }
        :deep(img) {
          object-fit: cover;
          border-radius: 8px;
          margin: 96px 0;
          max-width: 100%;
          width: 100%;
          max-height: 358px;
          height: auto;
          text-align: center;
          display: block;
        }
        :deep(p:last-child) {
          margin-bottom: 0;
          padding-bottom: 0;
        }
        :deep(ul) {
          margin-bottom: 2rem;
          margin-left: 3.5rem;
          margin-right: 2rem;
          font-size: 24px;
          line-height: 32px;
          color: #252525;
          word-break: break-all;
        }
        :deep(h1) {
          font-size: 64px;
          padding: 2rem 0;
        }
        :deep(strong) {
          display: block;
          font-weight: 400;
        }
      }
    }
  }
}
@media screen and (max-width: 1440px) {
  .main-container {
    .container-1 {
      .part-2 {
        // width: 100%;
        width: calc(100% - 64px) !important;
        .content {
          :deep(p) {
            // padding: 0 2rem;
            font-size: 24px;
            padding-bottom: 32px;
            line-height: 32px;
          }
          :deep(img) {
            object-fit: cover;
            border-radius: 8px;
            margin: 96px 0;
            max-width: 100%;
            max-height: 358px;
            height: auto;
          }
          :deep(p:last-child) {
            margin-bottom: 0;
            padding-bottom: 0;
          }

          :deep(ul) {
            margin-bottom: 2rem;
            margin-left: 3.5rem;
            margin-right: 2rem;
            font-size: 24px;
            line-height: 32px;
            color: #252525;
            word-break: break-all;
          }

          :deep(h1) {
            font-size: 64px;
            padding: 2rem 0;
          }
        }
      }
    }
  }
}
// //
// ul {
//       padding-bottom: 2rem;
//       padding-left: 1.5rem;
//       padding-right: 2rem;
//       font-size: 24px;
//       line-height: 32px;
//       color: #252525;
//       word-break: break-all;
//     }
//

@media screen and (max-width: 1200px) {
  .main-container {
    .container-1 {
      .part-2 {
        // width: 100%;
        width: calc(100% - 64px) !important;
        .content {
          :deep(p) {
            //   padding: 0 2rem;
            font-size: 24px;
            padding-bottom: 32px;
            line-height: 32px;
          }
          :deep(img) {
            object-fit: cover;
            border-radius: 8px;
            margin: 96px 0;
            max-width: 100%;
            max-height: 358px;
            height: auto;
          }
          :deep(p:last-child) {
            margin-bottom: 0;
            padding-bottom: 0;
          }
          :deep(ul) {
            margin-bottom: 2rem;
            margin-left: 3.5rem;
            margin-right: 2rem;
            font-size: 24px;
            line-height: 32px;
            color: #252525;
            word-break: break-all;
          }

          :deep(h1) {
            font-size: 64px;
            padding: 2rem;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .main-container {
    .container-1 {
      width: 100%;
      .header-image {
        img {
          height: auto;
          width: 100%;
          max-width: 100%;
          max-height: unset;
          aspect-ratio: 1/1;
          border-radius: 8px;
        }
      }
      .part-2 {
        width: 100% !important;

        .content {
          :deep(p) {
            // padding: 0 1.5rem;
            padding-left: 0.5rem;
            padding-right: 0.5rem;
            font-size: 16px;
            line-height: 24px;
            padding-bottom: 2rem;
            a {
              word-break: break-all;
            }

            ul {
              font-size: 16px;
              margin-left: 2.6rem;
              margin-right: 1.5rem;
              line-height: 24px;
              margin-bottom: 1.5rem;
            }
          }
          :deep(img) {
            margin: 64px 0;
            object-fit: cover;
            width: 100%;
            height: auto;
            aspect-ratio: 1/1;
            max-width: 100%;
          }

          :deep(p:last-child) {
            margin-bottom: 0;
            padding-bottom: 0;
          }

          :deep(ul) {
            font-size: 16px;
            margin-left: 2.6rem;
            margin-right: 1.5rem;
            line-height: 24px;
            margin-bottom: 1.5rem;
          }

          :deep(h1) {
            padding: 1.5rem;
            font-size: 32px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .main-container {
    .container-1 {
      .header-image {
        img {
          height: unset;
          width: 100%;
          max-width: 100%;
          max-height: unset;
          aspect-ratio: 1/1;
          border-radius: 8px;
        }
      }
      .part-2 {
        width: 100% !important;
        .content {
          :deep(img) {
            max-width: 100%;
            width: 100%;
            object-fit: cover;
            border-radius: 8px;
            aspect-ratio: 1/1;
            // max-height: 256px;
          }
          :deep(h1) {
            font-size: 32px;
            padding: 1.5rem;
          }
        }
      }
      .part-3 {
        width: calc(100% - 16px);
        .recent-blogs {
          img {
            // height: 256px !important;
            width: 100%;
            border-radius: 8px;
            max-width: 100%;
            // max-height: 256px !important;
            aspect-ratio: 1/1;
          }
        }
      }
    }
  }
}

@import "../../../public/assets/css/blog.scss";
</style>
